// 3rd-party Plugins
@import '~highlight.js/styles/googlecode.css';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

// Theme Styles
@import './_metronic/_assets/sass/style.react.scss';

// Default Layout themes
@import './_metronic/_assets/sass/themes/layout/header/base/light.scss';
@import './_metronic/_assets/sass/themes/layout/header/menu/light.scss';
@import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
@import './_metronic/_assets/sass/themes/layout/aside/dark.scss';

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import './_metronic/_assets/sass/themes/layout/brand/dark.scss';
}
// Light
.brand-light {
  @import './_metronic/_assets/sass/themes/layout/brand/light.scss';
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.react-bootstrap-table-editing-cell input {
  // background-color: lime !important;
  height: 32px !important;
}

.uppy-Dashboard-inner {
  height: 150px !important;
}

.uppy-StatusBar {
  display: none !important;
}

.uppy-Dashboard-files {
  padding: 0px !important;
}

.full-tooltip > .tooltip-inner {
  max-width: 100%;
}

.mobile-padding {
  padding-right: 0px !important;
}

.metric-select {
  display: inline-flex;
}

@media (max-width: 480px) {
  .mobile-padding {
    padding-right: 0px !important;
  }
  .metric-select {
    display: unset;
  }
}

@media (min-width: 1200px){

  .cus-analytics-box .col-xl-2 {
  
  flex: 0 0 20%;
  max-width: 20%;
  }
  
  }
// .react-bootstrap-table-editing-cell select {
//   // background-color: lime !important;
//   height: 32px !important;
// }

.active-input{
  border: 0.5px solid $primary;
  border-radius: 0.42rem;
}

.map-content{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 55px;
}

.timeStampColor {
  color: gray;
  font-size: 11px;
}

.table-card-body {
  padding-top: 10px !important;
}

#kt_content {
  margin-top: 0px;
  margin-bottom: -38px;
}

.row-expansion-style {
  margin-right: 1rem !important;
  padding-bottom: 0px !important;
}

.RichTextEditor__root___2QXK- {
  width: 96.5%;
  margin-left: auto;
  margin-right: auto;
  min-height: 20rem;
}

.round-border {
  border-radius: 50%;
  border: 1px solid black;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 0px 10px;
  height: 25px;
  width: 28px;
  // padding: 2px 7px;
}

// .gutter-b {
//   margin-bottom: 10px !important;
// }

.menu-link{
  align-items: center !important;
}

.card-spaces {
  margin-bottom: 10px !important;
}

.react-bootstrap-table table {
  table-layout: unset !important;
}

.css-1wa3eu0-placeholder {
  color: #3f4254 !important;
}
.reset-expansion-style {
  background-color: #eeeeee !important ;
}
.custom-card-header {
  min-height: 53px !important;
}

.ellipsisText {
  max-width: 28rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 7rem !important;
}

.selectedCard {
  background-color: #cce6ff !important;
}

// .image-input-wrapper {
//   background-size: contain !important;
// }

.ellipsisTextCard {
  // max-width: 28rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // padding-right: 4rem !important;
}

.table.table-head-custom thead tr,
.table.table-head-custom thead th {
  color: unset !important;
  // color: #545461 !important;
  font-weight: 600 !important;
  font-size: 0.95rem !important;
  text-transform: unset !important;
  letter-spacing: 0 !important;
}

.nav.nav-pills .nav-link {
  background: #fff !important;
}
.nav.nav-pills .show > .nav-link,
.nav.nav-pills .nav-link.active {
  background-color: #3699ff !important;
}

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

// .table.table-primary-heading thead tr {
//   color: #f64e60 !important;
// }

