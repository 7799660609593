.small-line {
    float: left;
    /* width: 10.2rem; */
    height: 30px;
    margin-right: 10px;
    border-radius: 3px;
    background-image: linear-gradient(90deg, #f9f9f9 0px, rgba(229, 229, 229, 0.8) 40px, #f9f9f9 80px);
    background-size: 600px;
    animation: shine-lines 2s infinite ease-out;
  }
  
  @keyframes shine-lines {
    0% {
      background-position: -100px;
    }
  
    40%,
    100% {
      background-position: 140px;
    }
  }