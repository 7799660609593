//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary: #eb3a22; // Bootstrap variable
$primary-hover: #EB711D; // Custom variable
$primary-light: #E1F0FF; // Custom variable
$primary-inverse: #FFFFFF; // Custom variable

.border-dashed {
    border-style: dashed !important;
    border-color: #eb3a22
}

.bg-skin-primary {
    background-color: #fce2de;
}

.bottom-popup {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 90vh;
    overflow-y: auto;
}

.scroll-popup{
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.form-check-box {
    --bs-form-check-bg: transparent;
    flex-shrink: 0;
    width: 1.75rem;
    height: 1.75rem;
    margin-top: -.125rem;
    vertical-align: top;
    appearance: none;
    background-color: var(--bs-form-check-bg);
    background-image: var(--bs-form-check-bg-image);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid var(--bs-gray-300);
    print-color-adjust: exact
}

.custom-button {
    background-color: #eb3a22;
    border: 1px solid #eb3a22;
    color: #fff;
    padding: calc(.45rem + 1px) calc(1.5rem + 1px);
    border-radius: 5px;
    // width: 100%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: none;
}

.custom-button:hover {
    background-color: #eb3a22;
    color: white;
}

.text-blue {
    color: #056EE9;
}

.app-sidebar-separator {
    border-bottom: 1px dashed #DBDFE9;
    margin: .75rem 0
}

.separator {
    display: block;
    height: 0;
    border-bottom: 1px dashed #DBDFE9;
}

.text-100{
    color: #F9F9F9;
}

.text-200{
    color: #F1F1F4;
}

.text-300{
    color: #DBDFE9;
}

.text-400{
    color: #C4CADA;
}

.text-500{
    color: #99A1B7;
}

.text-600 {
    color: #78829D;
}

.text-700 {
    color: #4B5675;
}

.text-800 {
    color: #252F4A;
}

.text-900 {
    color: #071437;
}

.bg-gray-light{
    background-color: #F9F9F9;
}

.custom-button-danger {
    background-color: transparent;
    border: 1px solid #eb3a22;
    color: #eb3a22;
    padding: calc(.45rem + 1px) calc(1.5rem + 1px);
    border-radius: 5px;
    // width: 25%;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.custom-button-danger:hover {
    background-color: #eb3a22;
    color: white;
}

.custom-custom-btn-light {
    background-color: #f3f6f9;
    border: 1px solid #f3f6f9;
    color: #252F4A;
    padding: calc(.45rem + 1px) calc(1.5rem + 1px);
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    // background-color: #f3f6f9;
    // border: 1px solid #f3f6f9;
    // color: #7e8299;
    // padding: calc(.65rem + 1px) calc(1.5rem + 1px);
    // border-radius: 5px;
    // cursor: pointer;
    // transition: background-color 0.3s, color 0.3s;
}

.custom-custom-btn-light:hover {
    background-color: #dde6ee;
    color: #70758f;
}

.bg-light-common {
    background-color: #fce2de;
}

.bg-light {
    background-color: rgba(249, 249, 249);
}

.sidebar-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 310px;
    background-color: #fff;
    border-left: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    /* Allow vertical scrolling */
    /* Set a fixed height or max-height for the sidebar */
    height: 100vh;
    /* Adjust as needed */
}

@media (max-width: 767px) {
    .slider-shop .swiper-slide h1 {
        font-weight: 400;
        color: #000000;
        font-size: 38px;
    }

    .mobile-banner {
        padding: 40px 0px 80px 0;
    }

    .mobile-banner #main-slider {
        height: inherit;
    }

    .banner.marketing-banner {
        padding: 80px 0px 80px 0;
    }

    .banner.marketing-banner #main-slider {
        height: inherit;
    }

    .banner.marketing-banner .hero-app-1,
    .banner.marketing-banner .hero-app-2,
    .banner.marketing-banner .hero-app-3,
    .banner.marketing-banner .hero-app-4,
    .banner.marketing-banner .hero-app-5,
    .banner.marketing-banner .hero-app-6,
    .banner.marketing-banner .hero-app-7 {
        display: none;
    }
}

img.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

img.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

@keyframes mover {
    0% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        -webkit-transform: translateY(-10px);
        transform: translateY(-10px);
    }
}

.custom-animation {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping 9s ease-in-out 2s infinite alternate;
    animation: jumping 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all .9s ease 5s;
    transition: all .9s ease 5s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation2 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping2 9s ease-in-out 2s infinite alternate;
    animation: jumping2 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 1s ease 3s;
    transition: all 1s ease 3s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation3 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping3 9s ease-in-out 2s infinite alternate;
    animation: jumping3 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 7s ease 2s;
    transition: all 7s ease 2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation4 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping4 9s ease-in-out 2s infinite alternate;
    animation: jumping4 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 8s ease 4s;
    transition: all 8s ease 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation10 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping10 9s ease-in-out 2s infinite alternate;
    animation: jumping10 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 8s ease 4s;
    transition: all 8s ease 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.custom-animation11 {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-animation: jumping11 9s ease-in-out 2s infinite alternate;
    animation: jumping11 9s ease-in-out 2s infinite alternate;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    -webkit-transition: all 8s ease 4s;
    transition: all 8s ease 4s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@-webkit-keyframes jumping {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .8;
    }

    25% {
        -webkit-transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        opacity: .9;
    }

    50% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        opacity: .6;
    }

    85% {
        -webkit-transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        opacity: .85;
    }
}

@keyframes jumping {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .8;
    }

    25% {
        -webkit-transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        transform: translateY(-10px) translateX(-10px) rotate(20deg) scale(0.8);
        opacity: .9;
    }

    50% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        transform: translateY(-15px) translateX(-15px) rotate(10deg) scale(0.9);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        transform: translateY(-20px) translateX(-20px) rotate(20deg) scale(0.75);
        opacity: .6;
    }

    85% {
        -webkit-transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        transform: translateY(-25px) translateX(-25px) rotate(20deg) scale(0.9);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        transform: translateY(-15px) translateX(-15px) rotate(0) scale(0.95);
        opacity: .85;
    }
}

@-webkit-keyframes jumping2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .5;
    }

    25% {
        -webkit-transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        opacity: .9;
    }
}

@keyframes jumping2 {
    0% {
        -webkit-transform: translateY(0px) translateX(0) rotate(0) scale(1);
        transform: translateY(0px) translateX(0) rotate(0) scale(1);
        opacity: .5;
    }

    25% {
        -webkit-transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        transform: translateY(-30px) translateX(10px) rotate(20deg) scale(0.8);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.7);
        opacity: .8;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(20deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.75);
        opacity: .9;
    }
}

@-webkit-keyframes jumping3 {
    0% {
        -webkit-transform: translateY(10px) translateX(0) rotate(0) scale(1);
        transform: translateY(10px) translateX(0) rotate(0) scale(1);
        opacity: .9;
    }

    20% {
        -webkit-transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        opacity: .6;
    }

    100% {
        -webkit-transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        opacity: .7;
    }
}

@keyframes jumping3 {
    0% {
        -webkit-transform: translateY(10px) translateX(0) rotate(0) scale(1);
        transform: translateY(10px) translateX(0) rotate(0) scale(1);
        opacity: .9;
    }

    20% {
        -webkit-transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        transform: translateY(20px) translateX(10px) rotate(-20deg) scale(0.8);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        transform: translateY(15px) translateX(-15px) rotate(10deg) scale(0.75);
        opacity: .8;
    }

    40% {
        -webkit-transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        transform: translateY(-15px) translateX(-25px) rotate(10deg) scale(0.5);
        opacity: 1;
    }

    80% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        transform: translateY(-30px) translateX(20px) rotate(-20deg) scale(0.75);
        opacity: .6;
    }

    100% {
        -webkit-transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        transform: translateY(15px) translateX(15px) rotate(0) scale(0.95);
        opacity: .7;
    }
}

@-webkit-keyframes jumping4 {
    0% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        opacity: .7;
    }

    25% {
        -webkit-transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        opacity: .9;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        opacity: .9;
    }
}

@keyframes jumping4 {
    0% {
        -webkit-transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        transform: translateY(-30px) translateX(20px) rotate(0) scale(1.2);
        opacity: .7;
    }

    25% {
        -webkit-transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        transform: translateY(-20px) translateX(10px) rotate(50deg) scale(0.6);
        opacity: .8;
    }

    50% {
        -webkit-transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        transform: translateY(15px) translateX(-15px) rotate(20deg) scale(0.5);
        opacity: .9;
    }

    75% {
        -webkit-transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        transform: translateY(30px) translateX(20px) rotate(50deg) scale(0.75);
        opacity: .7;
    }

    100% {
        -webkit-transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        transform: translateY(-15px) translateX(15px) rotate(0) scale(0.5);
        opacity: .9;
    }
}

@-webkit-keyframes jumping10 {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        display: block;
    }
}

@keyframes jumping10 {
    0% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg);
        transform: rotate(30deg);
        display: block;
    }
}

@-webkit-keyframes jumping11 {
    0% {
        -webkit-transform: rotate(180deg) translate(-20px, 20px);
        transform: rotate(180deg) translate(-20px, 20px);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg) translate(0px, 0px);
        transform: rotate(30deg) translate(0px, 0px);
        display: block;
    }
}

@keyframes jumping11 {
    0% {
        -webkit-transform: rotate(180deg) translate(-20px, 20px);
        transform: rotate(180deg) translate(-20px, 20px);
        display: block;
    }

    100% {
        -webkit-transform: rotate(30deg) translate(0px, 0px);
        transform: rotate(30deg) translate(0px, 0px);
        display: block;
    }
}

.about-pattern img {
    position: relative;
    left: -100px;
    top: -50px;
}

.hero-app-1 {
    position: absolute;
    left: 4%;
    top: 10%;
}

@media (max-width: 576px) {
    .hero-app-1 {
        display: none;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {

    /* line 420, E:/167 Bizcon Business/Bizcon_html/sass/_common.scss */
    .hero-app-1 {
        padding: 8px 25px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-app-1 {
        padding: 10px 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-app-1 {
        padding: 10px 25px;
    }
}

.hero-app-2 {
    position: absolute;
    left: 5%;
    bottom: 35%;
}

@media (max-width: 576px) {
    .hero-app-2 {
        bottom: 5%;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-app-2 {
        bottom: 20%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-app-2 {
        padding: 10px 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-app-2 {
        padding: 10px 25px;
    }
}

.hero-app-3 {
    position: absolute;
    top: 20%;
    left: 25%;
}

@media (max-width: 576px) {
    .hero-app-3 {
        left: 5%;
    }
}

.hero-app-4 {
    position: absolute;
    bottom: 20%;
    left: 50%;
}

.hero-app-5 {
    position: absolute;
    bottom: 40%;
    left: 50%;
}

.hero-app-6 {
    position: absolute;
    right: 5%;
    top: 40%;
}

.hero-app-7 {
    position: absolute;
    top: 13%;
    left: 51%;
}

.hero-app-8 {
    position: absolute;
    top: 85% !important;
    right: 20% !important;
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .hero-app-7 {
        top: 5%;
        left: 73%;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .hero-app-7 {
        padding: 10px 25px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-app-7 {
        padding: 10px 25px;
    }
}

.hero-app-8 {
    position: absolute;
    left: 68%;
    top: 60%;
}

@media (max-width: 576px) {
    .hero-app-8 {
        top: 80%;
    }

    .page-header {
        padding: 150px 0px 0px 0;
    }
}

@media (max-width: 575px) {

    .testimonial-style-01 .testimonial-img-03 img,
    .testimonial-img-01 img,
    .testimonial-img-02 img {
        display: none;
    }
}

@media (max-width: 991px) {
    .page-header {
        padding: 150px 0px 100px 0;
    }
}

.rotate-animation {
    -webkit-animation: rotation 99s infinite linear;
    animation: rotation 99s infinite linear;
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

.back-to-top a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    // bottom: 50px;
    right: 40px;
    width: 50px;
    height: 50px;
    margin: 0px;
    color: #ffffff;
    font-size: 18px;
    background: #EB4721;
    color: #ffffff;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    z-index: 999;
    border-radius: 5px;
}

.back-to-top a:hover {
    color: #ffffff;
}

.back-to-top a:focus {
    color: #ffffff;
}