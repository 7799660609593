.custom___fonts{
    font-size: 15; 
    color: "#000"; 
    font-weight: "500"; 
    width: 200; 
  }

  .svg-icon.svg-icon-danger svg [fill]:not(.permanent):not(g) {
    fill: #eb3a22 !important;
  } 

  .table-row {
    transition: transform 0.5s ease-in-out;
  }